<template>
    <ul class="login-call-to-actions">
        <li v-if="signupEntry">
            <BaseButton
                :element="NuxtLink"
                :to="`/${signupEntry.uri}`"
                size="large"
                icon="arrow-right"
                @click="useGtm()?.trackEvent({
                    event: 'cta_click',
                    ctaText: 'Meld je aan',
                    ctaUrl: `/${signupEntry.uri}`
                })"
            >
                Meld je aan
            </BaseButton>
        </li>
        <li v-if="membershipInfoEntry">
            <BaseButton
                :element="NuxtLink"
                :to="`/${membershipInfoEntry.uri}`"
                size="large"
                icon="arrow-right"
                class="base-button--secondary"
                @click="useGtm()?.trackEvent({
                    event: 'cta_click',
                    ctaText: 'Meer over TLN',
                    ctaUrl: `/${membershipInfoEntry.uri}`
                })"
            >
                Meer over TLN
            </BaseButton>
        </li>
    </ul>
</template>

<script setup>
import { useNavigationStore } from '~/store/navigation';
import { useGtm } from '@gtm-support/vue-gtm';

const navigationStore = useNavigationStore();
const NuxtLink = resolveComponent('NuxtLink');

const signupEntry = computed(() => {
    return navigationStore.signupEntry;
});

const membershipInfoEntry = computed(() => {
    return navigationStore.membershipInfoEntry;
});
</script>

<style lang="less" src="./LoginCallToActions.less"></style>
