<template>
    <HomePageLoggedIn v-if="loggedIn" />
    <HomePageLoggedOut v-else />
</template>

<script setup>
import { useAuthStore } from '~/store/auth';

const authStore = useAuthStore();

const loggedIn = computed(() => authStore.loggedIn);
</script>
