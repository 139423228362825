<template>
    <TemplateBase
        :entry="entry"
    >
        <template #hero>
            <HeroHome :entry="entry" />
        </template>

        <template #default>
            <BaseSection
                v-if="followingDossierIds.length === 0 && featuredDossierOptions"
                class="section--spacing-md section--title-large"
                section-width="content"
                title="Direct de laatste updates ontvangen?"
            >
                <p>Volg dossiers en krijg de laatste updates in je mailbox.</p>

                <DossierFollowList
                    :dossiers="featuredDossierOptions"
                    :primary-add-button="true"
                    button-size="large"
                    more-button-class="base-button--primary"
                >
                    <template #title>Suggesties voor jou</template>
                </DossierFollowList>
            </BaseSection>

            <EntriesListSection
                v-else-if="personalEntries.length > 0"
                title="Jouw laatste dossierupdates"
                class="section--title-small section--spacing-md"
                :entries="personalEntries"
            >
                <template #after>
                    <BaseButton
                        v-if="showLoadMoreUpdatesButton"
                        class="base-button--tertiary"
                        size="large"
                        @click="loadMoreUpdates"
                    >
                        Toon meer updates
                    </BaseButton>
                </template>
            </EntriesListSection>

            <BaseSection
                v-else
                class="section--spacing-md section--title-large"
                section-width="content"
                title="Jouw laatste dossierupdates (0)"
            >
                <p>Helaas zijn er geen recente updates binnen de dossiers die je volgt.</p>

                <DossierFollowList
                    :dossiers="featuredDossierOptions"
                    :primary-add-button="true"
                    button-size="medium"
                    more-button-class="base-button--primary"
                    :hide-followed-dossiers="true"
                >
                    <template #title>Suggesties</template>
                </DossierFollowList>
            </BaseSection>
        </template>

        <template #contentBlocks>
            <ContentBlocks
                :content-blocks="entry.contentBlocks"
            />
        </template>

        <template #related>
            <EntriesListSection
                v-if="(followingDossierIds.length === 0 || personalEntries.length === 0) && latestArticles"
                title="Laatste updates"
                class="section--title-small section--spacing-md"
                :entries="latestArticles"
            />
        </template>

        <template #aside>
            <BasePanel title="Direct naar">
                <QuickLinks
                    :links="entry.sidebarLinks"
                />
            </BasePanel>
        </template>
    </TemplateBase>
</template>

<script setup>
import MyLatestUpdatesQuery from '~/graphql/queries/MyLatestUpdates.graphql';
import { useAuthStore } from '~/store/auth';

const authStore = useAuthStore();

const { variables }  = useCraftGraphql();
// We don't need the URI for this query
delete variables.uri;

const response = await useGuardedQuery('/home', variables);

const { craftEntry: entry } = useCraftPage(response);

const featuredDossierOptions = computed(() => entry?.value?.featuredDossiers);
const latestArticles = computed(() => response?.data?.value?.latestArticles);

// Personalized data
const perPage = 3;
const pageOffset = ref(0); // Offset for the next query
const personalEntries = ref([]); // Array containing all (loaded more) entries

const followingDossierIds = computed(() => {
    return authStore.userModel?.following ?? [];
});

const latestUpdatesVariables = computed(() => {
    return {
        dossierIds: toValue(followingDossierIds),
        offset: toValue(pageOffset) * perPage,
        limit: perPage
    };
});

const { data: latestUpdates, execute: executeLatestUpdatesQuery } = await useAsyncQuery({
    query: MyLatestUpdatesQuery,
    variables: latestUpdatesVariables,
}, {
    immediate: false // Don't fetch right away
});

if (toValue(followingDossierIds).length > 0) {
    await executeLatestUpdatesQuery();
}

// Total number of results
const personalEntriesTotal = computed(() => {
    if (!toValue(latestUpdates) || !toValue(latestUpdates).entryCount) {
        return 0;
    }

    return toValue(latestUpdates).entryCount;
});

watchEffect(() => {
    // If the user does follow dossiers,
    // merge the results of various requests in a single array
    personalEntries.value = [
        ...toValue(personalEntries),
        ...toValue(latestUpdates)?.entries ?? []
    ];
});

const loadMoreUpdates = () => {
    pageOffset.value++;
};

const showLoadMoreUpdatesButton = computed(() => {
    return toValue(personalEntriesTotal) > ((toValue(pageOffset) + 1) * perPage);
});
</script>
